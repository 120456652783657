<template>
  <div>
    <el-form ref="checkForm" :model="formInline" label-width="140px">
      <el-row class="search">
        <el-col :span="18">
          <el-row>
            <el-col :span="6">
              <el-form-item label="规则名称" prop="ruleName">
                <el-select
                  placeholder="请选择报警来源"
                  v-model="formInline.alertSource"
                >
                  <el-option label="一键报警" value="一键报警"></el-option>
                  <el-option label="场站火警" value="场站火警"></el-option>
                  <el-option label="全部" value></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="规则名称" prop="ruleName">
                <el-input
                  placeholder="请输入规则名称"
                  v-model="formInline.ruleName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="所属机构" prop="orgName">
                <selectOrg ref="selectOrg" @select="selectOrg"></selectOrg>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-col :span="6">
                <el-form-item label="生效日期">
                  <el-date-picker
                    type="daterange"
                    v-model="formInline.startEnd"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6">
          <el-row>
            <el-col :span="24">
              <el-form-item>
                <el-button type="primary" @click="search">查询</el-button>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="reset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-button type="primary" @click="add">新增</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>

    <div class="jy_table">
      <ctable
        ref="ctable"
        :columns="columns"
        :data="tableList"
        :total="total"
        operateWidth="200"
        isGenerate
        @cellClick="cellClick"
        @update="getList"
      >
      </ctable>
    </div>

    <addRule
      v-if="showRule"
      :rule="selectData"
      :isEdit="isEdit"
      :show="showRule"
      @close="closeDialog"
    ></addRule>
  </div>
</template>

<script>
import addRule from "./addRule";
import selectOrg from "@/components/dialog/selectOrg";
export default {
  name: "index",
  mounted() {
    this.$nextTick(() => {
      this.getList();
    });
  },
  methods: {
    closeDialog() {
      this.selectData = {};
      this.showRule = false;

      this.getList();
    },
    search() {
      this.getList();
    },
    async getList() {
      const page = this.$refs.ctable.getPager();
      let startTime, endTime;
      if (this.formInline.startEnd && this.formInline.startEnd.length) {
        startTime = this.formInline.startEnd[0];
        endTime = this.formInline.startEnd[1];
      }
      const result = await this.$http({
        url: "/alert/rule/queryPage",
        data: {
          ...page,
          ...this.formInline,
          startTime,
          endTime,
        },
      });
      this.tableList = result.detail.list;
      this.total = result.detail.total;
      this.tableList.forEach((v) => {
        this.handleTime(v)
        v.btnList = this.gernerateBtns(v);
      });
    },
    add() {
      this.isEdit = false;
      this.showRule = true;
    },
    reset() {
      this.formInline = {
        ruleName: "",
        orgId: "",
        alertSource: "",
        startEnd: [],
      };
      this.$refs.selectOrg.reset();
      this.getList();
    },
    cellClick(index, btnIndex, item) {
      if (btnIndex === 1) {
        this.selectData = item;
        this.selectData.startEnd = [item.enableT,item.disableT];
        this.selectData.noticeWay = item.noticeWay.toString();
        this.selectData.id = item.ruleId
        this.showRule = true;
        this.isEdit = true;
      } else if (btnIndex === 2) {
        this.$confirm("是否删除此规则?", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$http({
            url: "/alert/rule/delete",
            data: { id: item.ruleId },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          });
        });
      } else {
        this.$confirm("是否禁用此规则?", "禁用", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$http({
            url: "/alert/rule/disable",
            data: {
              status: item.status === "1" ? 0 : 1,
              id: item.ruleId,
            },
          }).then((res) => {
            this.$message.success({
              message: res.msg,
              duration: 3000,
            });
            this.getList();
          });
        });
      }
    },
    selectOrg(data) {
      this.formInline.orgId = data.orgId;
    },
    gernerateBtns(row) {
      let btns = [
        {
          index: 1,
          title: "编辑",
        },
        {
          index: 2,
          title: "删除",
        },
      ];
      if (row.status === "1") {
        btns.push({
          title: "禁用",
          index: 3,
        });
      } else {
        btns.push({
          title: "启用",
          index: 4,
        });
      }
      return btns;
    },
    handleTime(row) {
        if (row.disableDate && row.disableTime) {
            row.disableT = row.disableDate.split(" ")[0] + " " + row.disableTime.split(" ")[1]
        }
        if (row.enableDate && row.enableTime) {
            row.enableT = row.enableDate.split(" ")[0] + " " + row.enableTime.split(" ")[1]
        }
    }
  },
  data() {
    return {
      formInline: {
        ruleName: "",
        orgId: "",
        alertSource: "",
        startEnd: [],
      },

      tableList: [],
      columns: [
        {
          prop: "alertSource",
          title: "报警来源",
          width: 150,
        },
        {
          prop: "ruleName",
          title: "规则名称",
          width: 150,
        },
        {
          prop: "ruleDesc",
          title: "规则描述",
          width: 150,
        },
        {
          prop: "enableT",
          title: "生效时间",
          width: 150,
        },
        {
          prop: "disableT",
          title: "禁用时间",
          width: 150,
        },
        {
          prop: "noticeWay",
          title: "通知方式",
          width: 250,
          formatter: (row, key, item) => {
            if (item[key] == 1) {
              return "智能电话+公众号";
            } else if (item[key] == 2) {
              return "短信+公众号";
            } else if (item[key] == 3) {
              return "智能电话+短信+公众号";
            } else {
              return "未知";
            }
          },
        },
        {
          prop: "orgName",
          title: "规则所属单位",
          width: 250,
        },
        {
          prop: "status",
          title: "状态",
          width: 150,
          formatter: (row, key, item) => {
            return item[key] == "1" ? "启用" : "禁用";
          },
        },
        {
          prop: "createUserName",
          title: "创建用户",
          width: 200,
        },
        {
          prop: "createTime",
          title: "创建时间",
          width: 150,
        },
      ],
      total: 0,

      showRule: false,
      isEdit: false,

      selectData: {},
    };
  },
  components: {
    addRule,
    selectOrg,
  },
};
</script>
