<template>
  <el-dialog :visible.sync="visible" :title="title" @close="handleClose">
    <el-steps :active="active" finish-status="success">
      <el-step title="设置响应规则"></el-step>
      <el-step title="设置通知对象"></el-step>
      <el-step title="完成"></el-step>
    </el-steps>
    <div class="firstStep" v-show="active === 0">
      <el-form ref="first" :model="form" :rules="formRules">
        <el-form-item label="选择机构" prop="orgId">
          <selectOrg
            :currentOrgName="rule.orgName"
            @select="selectOrg"
          ></selectOrg>
        </el-form-item>
        <el-form-item label="报警来源" prop="alertSource">
          <el-select v-model="form.alertSource" placeholder="请选择报警来源">
            <el-option label="一键报警" value="一键报警"></el-option>
            <el-option label="场站火警" value="场站火警"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="规则名称" prop="ruleName">
          <el-input
            v-model="form.ruleName"
            style="width:220px;"
            placeholder="请输入规则名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="规则描述" prop="timeGt">
          <el-row>
            <el-select
              v-model="form.alarmDesc"
              placeholder="选择规则类型"
              disabled
            >
              <el-option label="一键报警未处理时间" value="1"></el-option>
              <el-option label="报警连续次数" value="2"></el-option>
            </el-select>
            <span style="margin: 0 20px;">≥</span>
            <el-input-number v-model="form.timeGt"></el-input-number>
            <span style="font-size:10px;margin-left:10px;">秒</span>
          </el-row>
        </el-form-item>
        <el-form-item label="生效时间" prop="startEnd">
          <el-date-picker
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            v-model="form.startEnd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="通知方式" prop="noticeWay">
          <el-select v-model="form.noticeWay" placeholder="请选择通知方式">
            <el-option label="智能电话+公众号" value="1"></el-option>
            <el-option label="短信+公众号" value="2"></el-option>
            <el-option label="智能电话+短信+公众号" value="3"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="twoStep" v-show="active === 1">
      <p>触发一键报警响应通知对象</p>
      <transfer
        :defaultData="getDefaultUserIds"
        ref="transfer"
        @selectPersons="getPersons"
      ></transfer>
    </div>
    <el-row type="flex" justify="center" style="margin-top:10px;">
      <el-button @click="up" v-if="active != 0">上一步</el-button>
      <el-button @click="next" v-if="active != 2">下一步</el-button>
      <el-button type="primary" v-if="active == 2" @click="updateOrSave"
        >完成</el-button
      >
    </el-row>
  </el-dialog>
</template>

<script>
import transfer from "@/components/transfer";
import selectOrg from "@/components/dialog/selectOrg";

export default {
  name: "addRule",
  props: {
    show: Boolean,
    rule: Object,
    isEdit: Boolean,
  },
  data() {
    return {
      visible: false,

      active: 0,

      title: "",

      form: {
        orgId: "",
        alertSource: "",
        ruleName: "",
        alarmDesc: "一键报警未处理时间",
        timeGt: 0,
        noticeWay: "",
        startEnd: []
      },

      formRules: {
        orgId: [{ required: true, message: "请选所属组织", trigger: "change" }],
        alertSource: [
          { required: true, message: "请选择报警来源", trigger: "change" },
        ],
        ruleName: [
          { required: true, message: "请输入报警名称", trigger: "blur" },
        ],
        timeGt: [{ required: true, message: "请输入时间", trigger: "blur" }],
        noticeWay: [
          { required: true, message: "请选通知方式", trigger: "change" },
        ],
        startEnd: [
          { required: true, message: "选择生效时间", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    show: {
      handler() {
        this.visible = this.show;
      },
      immediate: true,
    },
    isEdit: {
      handler() {
        if (this.isEdit) {
          this.title = "编辑响应规则";
        } else {
          this.title = "新增响应规则";
        }
      },
      immediate: true,
    },
    rule: {
      handler() {
        this.form = this.rule;
      },
      immediate: true
    },
  },
  methods: {
    getPersons(persons) {
      this.form.orgDeptIds = persons;
    },
    async updateOrSave() {
      let result;
      if (this.isEdit) {
        result = await this.$http({
          url: "/alert/rule/update",
          data: this.form,
        });
      } else {
        result = await this.$http({
          url: "/alert/rule/save",
          data: this.form,
        });
      }
      this.$message.success({
        message: result.msg,
        duration: 3000,
      });
      this.handleClose();
    },
    next() {
      if (this.active === 0) {
        this.$refs.first.validate((valide) => {
          if (valide) {
            this.active++;
          } else {
            return false;
          }
        });
      } else if (this.active === 1) {
        if (this.form.orgDeptIds) {
          this.active++;
        } else {
          this.$message.warning({
            message: "请选择处理人员",
            duration: 3000,
          });
          return false;
        }
      } else {
        this.active++;
      }
      if (this.active > 2) {
        this.active = 0;
      }
    },
    up() {
      if (this.active-- < 1) {
        this.active = 0;
      }
    },
    handleClose() {
      this.$emit("close");
    },
    selectOrg(data) {
      this.$set(this.form, "orgId", data.orgId);
    },
    reset() {
      this.form = {
        orgId: "",
        alertSource: "",
        ruleName: "",
        alarmDesc: "一键报警未处理时间",
        timeGt: 0,
        noticeWay: "",
      };

      this.active = 0;
    },
  },
  computed: {
    getDefaultUserIds() {
      if (this.rule.alertRuleUser && this.rule.alertRuleUser.length) {
        return this.rule.alertRuleUser.map((v) => v.userId);
      } else {
        return [];
      }
    },
  },
  components: {
    transfer,
    selectOrg,
  },
};
</script>
